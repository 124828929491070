#nprogress .bar,
#nprogress .spinner {
    z-index: 999999 !important;
}
.password-toggle__2 {
    position: absolute;
    padding-top: 18px !important;
    right: 26px;
    opacity: 0.6;
}
.password-toggle__1 {
    position: absolute;
    right: 26px;
    opacity: 0.6;
}
.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}
