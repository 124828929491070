@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

@media (max-width: 576px) {
    .update-logo__clz {
        margin-left: 23vw;
        margin-top: 2vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }

    .etk-logo__clz {
        top: 5%;
        position: absolute;
        transform: scale(0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .white-section {
        clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto !important;
        height: 100vh !important;
    }
    .update-card__clz {
        top: 60% !important;
        left: 50% !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .update-logo__clz {
        margin-left: 28vw;
        margin-top: 2vh;
        margin-bottom: 4vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }
    .etk-logo__clz {
        width: 80%;
        top: 2%;
        left: 10%;
        position: absolute;
        transform: translate(0%, 15%);
    }
    .white-section {
        clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto !important;
        height: 100vh !important;
    }
    .update-card__clz {
        top: 55% !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .update-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        transform: translate(0%, 15%);
    }
    .white-section {
        clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto !important;
        height: 100% !important;
    }
    .update-card__clz {
        top: 60% !important;
        left: 55% !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .update-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        transform: translate(0%, 15%);
    }
    .white-section {
        clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto;
        height: 100vh;
    }
    .update-card__clz {
        top: 55% !important;
        left: 55% !important;
    }
}

@media (min-width: 1200px) {
    .update-logo__clz {
        margin-left: 4vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 10vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        top: 5%;
        left: 4%;
        position: absolute;
        transform: translate(0%, 15%);
        width: 30%;
    }
    .white-section {
        clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 40vw;
    }
    .video {
        width: 120%;
        height: auto;
    }

    .update-card__clz {
        left: 60% !important;
    }
}

.update-btn__clz {
    box-shadow: 0px 15px 20px 7px #bfbdbd !important;
}

.update-btn__clz:hover {
    background-color: white !important;
    color: black !important;
}

.update-card__clz {
    font-family: "Poppins";
    left: 60% !important;
    top: 55% !important;
    width: 30% !important;
    height: 67% !important;
}

.update-card__clz h3 {
    font-size: 35px;
}

.update-card__clz .form-group label {
    font-size: 20px;
}

.password-toggle__clz {
    position: absolute;
    padding-top: 42px;
    right: 35px;
    opacity: 0.6;
}

.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}
.submit-button__clz {
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    margin-left: 31%;
}
