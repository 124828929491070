.container__clz {
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 23px;
    display: flex;
    column-gap: 34px;
}
.third-container__clz {
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 23px;
    display: flex;
    column-gap: 34px;
}

.second-container__clz {
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 23px;
    display: flex;
    column-gap: 34px;
}

@media screen and (min-width: 2288px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 377px;
        padding-right: 309px;
        display: grid;
        row-gap: 20px;
    }
}

@media screen and (min-width: 2090px) and (max-width: 2287px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 271px;
        padding-right: 217px;
        display: grid;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1900px) and (max-width: 2089px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 208px;
        padding-right: 61px;
        display: grid;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1781px) and (max-width: 1900px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 98px;
        padding-right: 81px;
        display: grid;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1669px) and (max-width: 1780px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 67px;
        padding-right: 0px;
        display: grid;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1669px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 110px;
        padding-right: 60px;
        display: grid;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1317px) and (max-width: 1366px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 60px;
        padding-right: 60px;
        display: grid;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1237px) and (max-width: 1316px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 20px;
        padding-right: 20px;
        display: grid;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1236px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 200px;
        padding-right: 20px;
        display: grid;
        row-gap: 20px;
    }
}
@media screen and (min-width: 942px) and (max-width: 1099px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 130px;
        padding-right: 20px;
        display: grid;
        row-gap: 20px;
    }
}

@media screen and (min-width: 832px) and (max-width: 941px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 20px;
        padding-right: 20px;
        display: grid;
        row-gap: 20px;
    }
}

@media screen and (min-width: 688px) and (max-width: 831px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 200px;
        padding-right: 20px;
        display: grid;
        row-gap: 20px;
    }
}
@media screen and (min-width: 600px) and (max-width: 687px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 130px;
        padding-right: 20px;
        display: grid;
        row-gap: 20px;
    }
}

@media screen and (min-width: 0px) and (max-width: 599px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 34px;
        padding-right: 36px;
        display: grid;
        row-gap: 20px;
    }
}

.card-header__clz img {
    display: block;
    border: 0;
    width: 100%;
    height: 259px;
}
.card-body__clz {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding-top: 15px;
    padding-left: 10px; */
    min-height: 130px;
    padding: 20px;
}
.card__clz {
    margin: 10px;
    background-color: rgb(8, 8, 8);
    border-radius: 18px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 351px;
    height: 448px;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    filter: grayscale(70%);
    cursor: pointer;
}
.card__clz:hover {
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.5);
    transform: scale(1.1);
    filter: grayscale(0%);
}
.home-page-card-title__clz {
    letter-spacing: 0px;
    color: #f2efef;
    margin-top: 0px;
    margin-left: 0px;
    text-transform: uppercase;
    font-family: "Bebas Neue";
    font-style: normal;
    font-display: swap;
    margin-bottom: 0px;
    font-size: 45px;
    line-height: 45px;
}
.home-page-card-description__clz {
    letter-spacing: 1px;
    color: #f2efef;
    opacity: 0.7;
    text-transform: uppercase;
    font-size: 17px;
    font-family: "Bebas Neue";
    font-size: 32px;
    line-height: 30px;
    padding-top: 5px;
}
.title__clz {
    text-align: center;
    padding-top: 22px;
    padding-right: 27%;
    padding-left: 28%;
    padding-bottom: 40px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 0%;
        padding-left: 0%;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 6%;
        padding-left: 4%;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 4%;
        padding-left: 4%;
        padding-bottom: 40px;
    }
}
.etk-title__clz {
    text-align: center;
    padding-top: 22px;
    font-size: 55px;
    color: #2f2b2b;
    font-weight: bold;
    font-family: "Bebas Neue";
}
.opacity-title__clz {
    opacity: 0.7;
    font-style: normal;
    font-display: swap;
    font-weight: bold;
    font-size: 55px;
    font-family: "Bebas Neue";
}
